import React, { useState, useContext, useEffect } from 'react'
import StoreContext from 'contexts/store'
import NotificationsContext from 'contexts/notifications'
import useSiteMetadata from 'hooks/useSiteMetadata'
import LocalStorage from 'utils/LocalStorage'

const storage = new LocalStorage('cart')

const WithStoreContext = props => {
	// Сервер или клиент
	const isClient = typeof window !== 'undefined'

	const defaultStorageData = {
		databases: [],
		summary: {
			price: 0,
			discount: 0,
			priceTotal: 0,
		},
		promo: null,
		loading: false,
	}
	if (!storage.get('data')) storage.set('data', defaultStorageData)

	const notificationsContext = useContext(NotificationsContext)
	const { siteApiUrl } = useSiteMetadata()
	const [context, _setContext] = useState({ data: isClient ? storage.get('data') : defaultStorageData })

	// Обновление данных в состоянии и LocalStorage
	const setContextData = data => {
		_setContext({ ...context, data })
		storage.set('data', data)
	}

	// Обновление данных на основе изменений в LocalStorage
	// Взаимодействие с несколькими вкладками
	useEffect(() => {
		const interval = setInterval(() => {
			if (JSON.stringify(context.data) === JSON.stringify(storage.get('data'))) return
			setContextData(storage.get('data'))
		}, 1000)
		return () => clearInterval(interval)
	})

	// Обновление корзины при повторном открытии сайта. Цены могли измениться
	useEffect(() => {
		;(() => {
			// Не обновляем на странице заказа после Яндекс Кассы, т.к. корзина очищается, а запрос возвращает состояние
			if (window.location.pathname.indexOf('/order/') === 0) return

			const data = storage.get('data')

			// Не обновляем, если баз в корзине нет
			if (!data.databases.length) return

			const types = data.databases.map(v => v.type)
			const promo = (data.promo || {}).name

			// Добавление индикатора загрузки корзины
			setContextData({ ...context.data, loading: true })
			updateCart({ types, promo }).then(() => {})
		})()
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [])

	// Отправка данных на сервер, получение ответа и обновление корзины
	const updateCart = async ({ types, promo }) => {
		try {
			const response = await fetch(`${siteApiUrl}/store/cart`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json;charset=utf-8' },
				body: JSON.stringify({
					databases: types.map(name => ({ name })),
					promo,
				}),
			})

			if (!response.ok) {
				notificationsContext.add({
					title: 'Возникла техническая ошибка',
					text: 'Мы уже знаем о проблеме. Пожалуйста, попробуйте позднее',
				})
				return
			}

			const data = await response.json()
			setContextData(data)
		} catch (e) {
			notificationsContext.add({
				title: 'Плохое соединение с интернетом',
				text: 'Пожалуйста, обновите страницу или используйте другой браузер',
			})
		}
	}

	// Существование базы в списке
	const isDatabaseExists = type => !!context.data.databases.find(v => v.type === type)

	// Добавление базы
	context.addDatabase = async type => {
		if (context.data.loading) return
		if (isDatabaseExists(type)) return

		// Добавление индикатора загрузки для текущей базы
		setContextData({
			...context.data,
			databases: [...context.data.databases, { type, loading: true }],
			loading: true,
		})

		const types = context.data.databases.map(v => v.type).concat(type)
		const promo = (context.data.promo || {}).name
		await updateCart({ types, promo })
	}

	// Удаление базы
	context.deleteDatabase = async type => {
		if (context.data.loading) return
		if (!isDatabaseExists(type)) return

		// Добавление индикатора загрузки для текущей базы
		setContextData({
			...context.data,
			databases: context.data.databases.map(v => {
				if (v.type === type) v.loading = true
				return v
			}),
			loading: true,
		})

		const types = context.data.databases.filter(v => v.type !== type).map(v => v.type)
		const promo = (context.data.promo || {}).name
		await updateCart({ types, promo })
	}

	// Использование промо-кода
	context.usePromo = async promo => {
		if (context.data.loading) return
		const name = (context.data.promo || {}).name

		// При удалении значений из поля, выполняется функция usePromo
		// WithAutoSaveDebounceComponent выполняется повторно
		if (!name && !promo) return

		// Добавление индикатора загрузки для корзины и промо-кода
		setContextData({
			...context.data,
			promo: {
				...context.data.promo,
				loading: true,
			},
			loading: true,
		})

		const types = context.data.databases.map(v => v.type)
		await updateCart({ types, promo })
	}

	// Очистка корзины
	context.clearStore = () => {
		setContextData(defaultStorageData)
	}

	return <StoreContext.Provider value={context}>{props.children}</StoreContext.Provider>
}

export default WithStoreContext
